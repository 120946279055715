import { useDocument } from 'common/use-cases/documents/use-document';
import { DocumentCategory } from 'entities/documents/documents-v2';

const useDocumentVerification = (category: DocumentCategory, verificationId: number) => {
  const { document, isLoading } = useDocument(category, verificationId);

  return {
    document,
    verification: document?.getVerification(verificationId),
    prototype: document?.prototype,
    isLoading,
  };
};

export { useDocumentVerification };
