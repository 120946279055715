import Form from '@swe/shared/ui-kit/components/form';

import SectionHeading from '@swe/shared/ui-kit/components/section-heading';

import Stack from '@swe/shared/ui-kit/components/stack';

import { useCallback, useState } from 'react';

import { createInitialValues, createValidationSchema, FormFields, FILE_INPUT_ACCEPT } from './config';

import { useDocumentMeta } from 'common/containers/document-reviewer/use-document-meta';
import { DocumentPrototype } from 'common/entities/documents/DocumentPrototype';
import { DocumentVerificationImpl } from 'common/entities/documents/DocumentVerification';
import { useCurrentUser } from 'common/providers/user';
import EditDocumentEndpoint from 'endpoints/profile/documents/edit-document';
import RevokeDocumentEndpoint from 'endpoints/profile/documents/revoke-document';
import { DocumentCategory, documentCategoryToDto } from 'entities/documents/documents-v2';

type DocumentReviewerProps = {
  category: DocumentCategory;
  verification: DocumentVerificationImpl;
  prototype: DocumentPrototype;
  onDocumentsUpdated?: () => any;
  onCancel?: () => any;
};

const DocumentReviewer = ({
  category,
  verification,
  prototype,
  onDocumentsUpdated,
  onCancel,
}: DocumentReviewerProps) => {
  const { revalidate } = useCurrentUser();
  const { errors, isErrorsFixed, handleFormChange } = useDocumentMeta(verification.data);
  const [isFormDisabled, setFormDisabled] = useState(false);

  const handleRevoke = useCallback(async () => {
    try {
      setFormDisabled(true);
      await RevokeDocumentEndpoint.request({ id: verification.id, documentType: documentCategoryToDto(category) });
      await revalidate();
      onDocumentsUpdated?.();
    } catch (e) {
      console.error(e);
    } finally {
      setFormDisabled(false);
    }
  }, [category, onDocumentsUpdated, revalidate, verification.id]);
  const handleFormSubmit = useCallback(
    async ({ frontSide, backSide }: FormFields) => {
      if (!isErrorsFixed) return;

      try {
        await EditDocumentEndpoint.request({
          identityId: verification.id,
          documentType: documentCategoryToDto(category),
          files: [frontSide, backSide].filter(Boolean) as File[],
        });
        await revalidate();
        onDocumentsUpdated?.();
      } catch (e) {
        console.error(e);
      }
    },
    [isErrorsFixed, verification.id, category, revalidate, onDocumentsUpdated],
  );

  const RevokeButton = (
    <Form.Button
      color="danger"
      block={!onCancel}
      onClick={handleRevoke}
    >
      Revoke document
    </Form.Button>
  );

  const CancelButton = (
    <Form.Button
      color="light"
      disabled={isFormDisabled}
      onClick={onCancel!}
      block
    >
      Cancel
    </Form.Button>
  );

  return (
    <Form.Builder<FormFields>
      initialValues={createInitialValues(verification.data)}
      validationSchema={createValidationSchema(prototype.data)}
      onChange={handleFormChange}
      onSubmit={handleFormSubmit}
      disabled={isFormDisabled}
      autofocus={false}
      alwaysEmitOnChange
      name="document-reviewer"
    >
      <Stack
        key={prototype.id}
        spacing="none"
      >
        <SectionHeading
          size="md"
          subHeading={`To verify that you're eligible for purchase, we need a copy of your ${prototype.name.toLowerCase()}`}
        >
          {prototype.name}
        </SectionHeading>
        <Form.File
          name="frontSide"
          label="Front side"
          placeholder="Choose file"
          required={prototype.data.minImagesCount > 0}
          accept={FILE_INPUT_ACCEPT}
          error={errors.frontSide}
        />
        {prototype.data.maxImagesCount > 1 && (
          <Form.File
            name="backSide"
            label="Back side"
            placeholder="Choose file"
            required={prototype.data.minImagesCount > 1}
            accept={FILE_INPUT_ACCEPT}
            error={errors.backSide}
          />
        )}
      </Stack>
      <Stack>
        {onCancel ? RevokeButton : null}
        <Stack direction="row">
          {onCancel ? CancelButton : RevokeButton}
          <Form.SubmitButton
            disabled={!isErrorsFixed}
            block
          >
            Update
          </Form.SubmitButton>
        </Stack>
      </Stack>
    </Form.Builder>
  );
};

export type { DocumentReviewerProps };
export { DocumentReviewer };
