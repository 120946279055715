import { yup } from '@swe/shared/ui-kit/components/form';

import { InputFileAccept, InputFileValue, StoredFile } from '@swe/shared/ui-kit/components/form/file';

import { DocumentPrototype, DocumentVerification, ImageVerification } from 'entities/documents/documents-v2';

type FormFields = { frontSide?: InputFileValue; backSide?: InputFileValue };

// TODO: Remove code duplication
const FILE_VALIDATION_SPEC = yup.mixed().file().fileSize(20);
const REQUIRED_FILE_VALIDATION_SPEC = yup.mixed().file().fileSize(20).required('File is required');

const imageToFileLikeSource = (image: ImageVerification): StoredFile => ({
  name: 'File uploaded!',
  src: image.url,
});

const createInitialValues = (verification: DocumentVerification): FormFields => {
  const [frontSide, backSide] = verification.images;

  return {
    frontSide: frontSide ? imageToFileLikeSource(frontSide) : undefined,
    backSide: backSide ? imageToFileLikeSource(backSide) : undefined,
  };
};

const createValidationSchema = (prototype: DocumentPrototype) => {
  return yup.object({
    frontSide: prototype.minImagesCount > 0 ? REQUIRED_FILE_VALIDATION_SPEC : FILE_VALIDATION_SPEC,
    backSide: prototype.minImagesCount > 1 ? REQUIRED_FILE_VALIDATION_SPEC : FILE_VALIDATION_SPEC,
  });
};

const FILE_INPUT_ACCEPT = InputFileAccept.Image;

export type { FormFields };
export { createValidationSchema, createInitialValues, imageToFileLikeSource, FILE_INPUT_ACCEPT };
