import { useDocuments } from 'common/use-cases/documents/use-documents';
import { DocumentCategory } from 'entities/documents/documents-v2';

const useDocument = (category: DocumentCategory, verificationId: number) => {
  const { documents, isLoading } = useDocuments();

  return {
    document: documents?.getDocumentByVerificationId(category, verificationId),
    isLoading,
  };
};

export { useDocument };
