import { useEffect, useState } from 'react';

import { Documents } from 'common/entities/documents/Documents';
import { state } from 'common/use-cases/documents/local-state';
import { useDocumentsPrototypes } from 'common/use-cases/documents/use-documents-prototypes';
import { useDocumentsVerifications } from 'common/use-cases/documents/use-documents-verifications';

const useDocuments = () => {
  const [manager, setManager] = useState<Documents | undefined>(state.documents);
  const { verifications, isLoading: isVerificationsLoading } = useDocumentsVerifications();
  const { prototypes, isLoading: isPrototypesLoading } = useDocumentsPrototypes();

  useEffect(() => {
    if (verifications && prototypes) {
      state.documents = new Documents(verifications, prototypes);
      setManager(state.documents);
    }
  }, [verifications, prototypes]);

  return {
    documents: manager,
    isLoading: isVerificationsLoading || isPrototypesLoading,
  };
};

export { useDocuments };
