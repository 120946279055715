import { isFile } from '@swe/shared/ui-kit/components/form/file';

import { useCallback, useState } from 'react';

import { FormFields } from 'common/containers/document-reviewer/config';
import { DocumentVerification, isImageVerificationValid } from 'entities/documents/documents-v2';

const useDocumentMeta = (verification: DocumentVerification) => {
  const [imageFrontSide, imageBackSide] = verification.images;
  const [isFrontSideValid, setFrontSideUpdated] = useState(
    imageFrontSide ? isImageVerificationValid(imageFrontSide) : true,
  );
  const [isBackSideValid, setBackSideUpdated] = useState(
    imageBackSide ? isImageVerificationValid(imageBackSide) : true,
  );
  const errors = {
    frontSide: isFrontSideValid ? undefined : imageFrontSide.status?.name,
    backSide: isBackSideValid ? undefined : imageBackSide.status?.name,
  };
  const handleFormChange = useCallback(({ frontSide, backSide }: FormFields) => {
    if (frontSide && isFile(frontSide)) {
      setFrontSideUpdated(true);
    }

    if (backSide && isFile(backSide)) {
      setBackSideUpdated(true);
    }
  }, []);

  return {
    errors,
    isErrorsFixed: !errors.frontSide && !errors.backSide,
    handleFormChange,
  };
};

export { useDocumentMeta };
