import Loader from '@swe/shared/ui-kit/components/loader';

import Text from '@swe/shared/ui-kit/components/text';

import { DocumentReviewer } from 'common/containers/document-reviewer';
import { useDocumentVerification } from 'common/use-cases/documents/use-document-verification';
import { DocumentCategory, DocumentVerification } from 'entities/documents/documents-v2';

type DocumentDetailsProps = {
  category: DocumentCategory;
  verificationId: DocumentVerification['id'];
  onUpdate?: () => void;
  onCancel?: () => void;
};

const DocumentDetails = ({ category, verificationId, onUpdate, onCancel }: DocumentDetailsProps) => {
  const { verification, prototype, isLoading } = useDocumentVerification(category, verificationId);

  if (isLoading) {
    return (
      <Loader
        size="lg"
        centered
      />
    );
  }

  if (!verification || !prototype) {
    return <Text variant="headline">Document not found</Text>;
  }

  return (
    <DocumentReviewer
      category={category}
      verification={verification}
      prototype={prototype}
      onDocumentsUpdated={onUpdate}
      onCancel={onCancel}
    />
  );
};

export type { DocumentDetailsProps };
export { DocumentDetails };
