import createModernEndpoint, { APIErrorExtended } from '@swe/shared/network/endpoint-factories/modern';

import { DocumentVerificationId, DocumentCategoryDTO } from 'entities/documents/documents-v2';

type Params = {
  id: DocumentVerificationId;
  documentType: DocumentCategoryDTO;
};
type Data = undefined;
type Error = APIErrorExtended;

const NAME = '/Documents/Revoke';
const RevokeDocumentEndpoint = createModernEndpoint<Params, Data, Error>(NAME);

export default RevokeDocumentEndpoint;
