import { ApplicationPage } from 'app/types';
import { Page } from 'common/components/page';
import { DEFAULT_AUTHORIZED_PAGE_PROPS } from 'common/components/page/config';

import { useRouterQuery } from 'common/router';
import { Routes } from 'common/router/constants';
import { useGoPrevious } from 'common/use-cases/use-go-previous';
import { DocumentDetails } from 'domains/profile/containers/document-details';
import { DocumentCategory } from 'entities/documents/documents-v2';

const TITLE = 'Edit Document';

const DocumentDetailsPage: ApplicationPage = () => {
  const { category, verificationId } = useRouterQuery();
  const goBack = useGoPrevious({ defaultRoute: Routes.ProfileDocuments });

  return (
    <Page
      {...DEFAULT_AUTHORIZED_PAGE_PROPS}
      header={{
        title: TITLE,
        defaultBackRoute: Routes.ProfileDocuments,
        showBreadcrumbs: false,
        enableMarginBottom: false,
      }}
    >
      <DocumentDetails
        category={category as DocumentCategory}
        verificationId={parseInt(verificationId as string, 10)}
        onUpdate={goBack}
        onCancel={goBack}
      />
    </Page>
  );
};

DocumentDetailsPage.getMeta = () => ({ title: TITLE });

DocumentDetailsPage.getMainLayoutProps = () => ({
  size: 'md',
  centered: 'x',
});

export default DocumentDetailsPage;
