import { Document } from 'common/entities/documents/Document';
import { Prototypes } from 'common/entities/documents/Prototypes';
import { Verifications } from 'common/entities/documents/Verifications';
import { DocumentCategory } from 'entities/documents/documents-v2';

class Documents {
  private static getPresentedDocumentsTypes(category: DocumentCategory, verifications: Verifications) {
    return Array.from(
      new Set(
        verifications
          .getVerificationsByCategory(category)
          .map((verification) => verification.data.verificationDocumentType.id),
      ),
    );
  }

  readonly documents: Record<DocumentCategory, Document[]>;

  constructor(verifications: Verifications, prototypes: Prototypes) {
    const presentedTypesMap = {
      [DocumentCategory.Identity]: Documents.getPresentedDocumentsTypes(DocumentCategory.Identity, verifications),
      [DocumentCategory.Medical]: Documents.getPresentedDocumentsTypes(DocumentCategory.Medical, verifications),
      [DocumentCategory.Additional]: Documents.getPresentedDocumentsTypes(DocumentCategory.Additional, verifications),
    };

    const entries = Object.entries(presentedTypesMap).map(([category, presentedTypes]) => {
      return [
        category as DocumentCategory,
        presentedTypes.map(
          (typeId) =>
            new Document(
              category as DocumentCategory,
              prototypes.getPrototype(category as DocumentCategory, typeId)!,
              verifications.getVerificationByPrototype(category as DocumentCategory, typeId),
            ),
        ),
      ];
    });

    this.documents = Object.fromEntries(entries);
  }

  getDocuments(category: DocumentCategory) {
    return this.documents[category];
  }

  getDocumentByVerificationId(category: DocumentCategory, verificationId: number) {
    const documents = this.getDocuments(category);

    return documents.find((d) => !!d.verifications.find((v) => v.id === verificationId));
  }
}

export { Documents };
